<template>
    <div class=" w-full items-center justify-center flex cursor-pointer">
        <div id="requestQuote" @click="openPopup"
            class="red-bg w-40 rounded-lg h-11 secondary5 flex justify-center items-center whitespace-nowrap">
            REQUEST A QUOTE
        </div>
        <PopupComponent v-if="showPopup" @close="closePopup" @successPopup="onSuccessPopup" />
        <SuccesspopupComponent v-if="succesPopup" class="absolute w-full h-full top-0 z-20" />
    </div>
</template>

<script>
import PopupComponent from '../components/ContactusPopup.vue';
import SuccesspopupComponent from '@/components/SuccesspopupComponents.vue'


export default {
    components: {
        PopupComponent,
        SuccesspopupComponent

    },
    data() {
        return {
            showPopup: false,
            succesPopup: false,
        }
    },
    methods: {
        onSuccessPopup() {
            this.succesPopup = true;
            window.scrollTo(0, 0);
            setTimeout(() => {
                this.succesPopup = false;
            }, 3000);
        },
        openPopup() {
            this.showPopup = true;
        },
        closePopup() {
            this.showPopup = false;
        }
    },
}
</script>