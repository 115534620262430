<template>
    <div class="flex flex-col md:w-4/6 relative w-full">
        <div class="flex md:flex-row flex-col items-center justify-center">
            <div class="flex flex-col md:mr-6 w-full md:h-20">
                <p class="tohoma font-bold primary md:text-base text-sm">Name</p>
                <input v-model="firstName" class="focus:outline-none p-4 w-full secondary3 h-11 mt-4" required
                    placeholder="First Name*">
                <p v-if="firstNameEmpty" class=" mt-2 red-text ml-4 helvatica font-normal md:text-sm text-xs">{{ errorMessage }}</p>
            </div>
            <div class="flex flex-col w-full md:mt-6 md:h-14">
                <p class="tohoma font-bold primary text-base"></p>
                <input v-model="lastName" class="focus:outline-none p-4 w-full secondary3 h-11 mt-4" required
                    placeholder="Last Name*">
                <p v-if="lastNameEmpty" class=" mt-2 red-text ml-4 helvatica font-normal md:text-sm text-xs">{{errorMessage}}</p>
            </div>
        </div>
        <div class="flex md:flex-row flex-col items-center justify-center md:mt-8">
            <div class="flex flex-col md:mr-6 w-full md:mt-0 mt-8 md:h-20">
                <p class="tohoma font-bold primary md:text-base text-sm">E- Mail Address</p>
                <input v-model="email" class="focus:outline-none p-4 w-full secondary3 h-11 mt-4" required
                    placeholder="E-Mail Address*">
                <p v-if="emailEmpty" class=" mt-2 red-text ml-4 helvatica font-normal md:text-sm text-xs">{{errorMessage}}</p>
            </div>
            <div class="flex flex-col  w-full md:mt-0 mt-4 md:h-20">
                <p class="tohoma font-bold primary md:text-base text-sm">Phone Number</p>
                <input v-model="phoneNumber" class="focus:outline-none p-4 w-full secondary3 h-11 mt-4" required
                    placeholder="Phone Number*">
                <p v-if="phonenumberEmpty" class=" mt-2 red-text ml-4 helvatica font-normal md:text-sm text-xs">{{errorMessage}}</p>

            </div>
        </div>
        <div class="flex flex-col mt-8">
            <p class="primary font-bold tohoma md:text-base text-sm mb-4">Resume</p>
            <p class="helvatica secondary font-normal md:text-sm  text-xs mb-4">Please upload your resume here</p>
            <p v-if="resumeEmpty" class=" mt-2 red-text ml-4 helvatica font-normal md:text-sm text-xs mb-2">{{errorMessage}}</p>
        </div>
        <div id="chhoseFile" class="flex justify-center items-center w-full h-24 secondary5 mb-20">
            <label for="file-upload" class="flex flex-col items-center justify-center">
                <span v-if="fileName" class="secondary2 helvatica font-bold text-sm mb-3">{{ fileName }}</span>
                <span v-if="!fileName" class="secondary2 helvatica font-bold text-sm mb-3">Choose files or drag here</span>
                <img v-if="!fileName" class="w-6" src="../assets/uploadfile.png" alt="">
            </label>
            <input id="file-upload" type="file" class="hidden" ref="resume" @change="fileUpload()" accept=".pdf,.docx,.png,.jpeg,.jpg">
        </div>
        <button @click="submitForm" id="submit"
            class="absolute red-bg w-28 rounded-lg h-11 secondary5 right-0 bottom-0 md:mr-0">Submit</button>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    props: {
        advert: String
    },
    data() {
        return {
            errorMessage:"All fields mustbe filled.",

            firstNameEmpty: false,
            lastNameEmpty: false,
            emailEmpty: false,
            phonenumberEmpty: false,
            resumeEmpty: false,

            successPopup: false,
            firstName: null,
            lastName: null,
            email: null,
            phoneNumber: null,
            fileName: null,
        }
    },
    mounted() {
        console.log("advert: ", this.advert)
    },
    methods: {
        fileUpload() {
            const formData = new FormData();
            formData.append("resume", this.$refs.resume.files[0]);
            this.fileName = Object.fromEntries(formData.entries()).resume.name;
        },
        submitForm() {
            var regexPattern = /^\d+$/;
            var regexPatternMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (this.firstName == null || this.firstName == "") {
                this.firstNameEmpty = true;
                this.emailEmpty=false;
                this.lastNameEmpty=false;
                this.resumeEmpty=false;
                this.phonenumberEmpty=false;
            } else if (this.lastName == null || this.lastName == "") {
                this.lastNameEmpty = true;
                this.resumeEmpty=false;
                this.phonenumberEmpty=false;
                this.firstNameEmpty=false;
                this.emailEmpty=false;
            }
            else if (this.email == null || this.email == "") {
                this.resumeEmpty = false;
                this.phonenumberEmpty=false;
                this.firstNameEmpty=false;
                this.lastNameEmpty=false;
                this.emailEmpty=true;
            }
            else if (this.phoneNumber == null || this.phoneNumber == "") {
                this.phonenumberEmpty = true;
                this.resumeEmpty = false;
                this.firstNameEmpty=false;
                this.lastNameEmpty=false;
                this.emailEmpty=false;
            }
            else if (this.fileName == null) {
                this.resumeEmpty = true;
                this.phonenumberEmpty=false;
                this.firstNameEmpty=false;
                this.lastNameEmpty=false;
                this.emailEmpty=false;
            }
            else if(!regexPatternMail.test(this.email)){
                this.resumeEmpty = false;
                this.phonenumberEmpty=false;
                this.firstNameEmpty=false;
                this.lastNameEmpty=false;
                this.emailEmpty=true;
                this.errorMessage="Invalid E-mail format!"
            }
            else if(!regexPattern.test(this.phoneNumber)){
                this.resumeEmpty = false;
                this.phonenumberEmpty=true;
                this.firstNameEmpty=false;
                this.lastNameEmpty=false;
                this.emailEmpty=false;
                this.errorMessage="Please enter a string consisting only of digits."
            }
            else {
                this.firstNameEmpty = false;
                this.emailEmpty=false;
                this.lastNameEmpty=false;
                this.resumeEmpty=false;
                this.phonenumberEmpty=false;


                const formData = new FormData();
                formData.append("name", this.firstName + " " + this.lastName);
                formData.append("subject", this.advert)
                formData.append("email", this.email);
                formData.append("phoneNumber", this.phoneNumber);
                formData.append("resume", this.$refs.resume.files[0]);

                console.log(Object.fromEntries(formData.entries()).resume.name);

                axios.post("https://falcon-website-backend.vercel.app/application", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                    .then(response => {
                        console.log(response);
                    })
                    .catch(err => {
                        console.error(err);
                    });
                this.firstName = "", this.lastName = "", this.email = "", this.phoneNumber = "", this.$refs.resume = null,this.fileName=null;

                this.successPopup = true;

                this.$emit('success-popup', this.successPopup);
            }
        }

    }
}
</script>
<style scoped>
#chhoseFile {
    border: 1px solid #4F4F4F;
    border-radius: 10px;
}

input {
    border: 1px solid #4F4F4F;
    border-radius: 10px;
    color: black;
    background-color: #FAFAFA;
}
input[type="text"], 
input[type="email"], 
input[type="tel"], 
textarea {
    font-size: 18px;
    -webkit-text-size-adjust: none;
}




</style>