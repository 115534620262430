<template>
    <div class="popup-overlay" @click.self="closePopup">
      <div class="popup-content">
        <button class="close-btn" @click="closePopup">X</button>
        <div class=" flex flex-col w-full md:p-0 justify-center items-center mb-8 mt-8">
            <p id="title" class="secondary5 font-bold mb-8 tohoma">Contact Us</p>
            <p class="helvatica font-normal md:text-base text-sm secondary5 mb-8">Please fill and following form for your
                general enquiries.</p>
            <div class="flex md:flex-row flex-col w-full">
                <div class="flex flex-col md:w-1/2 w-full md:mb-0 mb-8">
                    <input v-model="name"
                        class="md:text-sm text-xs focus:outline-none focus:border-gray-400 md:mr-3 p-3.5  helvatica font-bold rounded-lg"
                        placeholder="Full Name *" required>
                    <p v-if="nameEmpty" class="secondary5 ml-4 mt-2">{{ errorMessagge }}</p>
                </div>
                <div class="flex flex-col md:w-1/2 w-full">
                    <input v-model="phoneNumber"
                        class="md:text-sm text-xs focus:outline-none focus:border-gray-400  w-full  p-3.5  helvatica font-bold rounded-lg"
                        placeholder="Phone Number *" required>
                    <p v-if="phoneNumberEmpty" class="secondary5 ml-4 mt-2">{{ errorMessagge }}</p>
                </div>
            </div>
            <div class="flex md:flex-row flex-col w-full mt-8">
                <div class="flex flex-col md:w-1/2 w-full md:mb-0 mb-8">
                    <input v-model="email"
                        class=" md:text-sm text-xs focus:outline-none focus:border-gray-400 md:mr-3  p-3.5  helvatica font-bold rounded-lg"
                        placeholder="E-Mail Address *" required>
                    <p v-if="emailEmpty" class="secondary5 ml-4 mt-2">{{ errorMessagge }}</p>
                </div>
                <div class="flex flex-col md:w-1/2 w-full">
                    <input v-model="subject"
                        class="text-sm focus:outline-none focus:border-gray-400 w-full  p-3.5  helvatica font-bold rounded-lg"
                        placeholder="Subject *" required>
                    <p v-if="subjectEmpty" class="secondary5 ml-4 mt-2">{{ errorMessagge }}</p>
                </div>
            </div>
            <div class="flex flex-col w-full">
                <textarea v-model="message"
                    class="md:text-sm text-xs w-full h-40 p-3.5 mt-8  helvetica font-bold rounded-lg focus:outline-none focus:border-gray-400"
                    placeholder="Message *" required maxlength="500"></textarea>
                <p v-if="messageEmpty" class="secondary5 ml-4 mt-2">{{ errorMessagge }}</p>
            </div>
            <button @click="sendMessage()"
                class=" mt-8 flex ml-auto bg-white  rounded-lg tohoma font-bold md:text-sm text-xs  primary p-3 w-28 justify-center items-center">Submit</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios"
  export default {
    props: {
      show: {
        type: Boolean,
        required: true,
      },
    },
    data() {
        return {
            errorMessagge: "All fields must be filled!",
            successPopup:true,
    
            nameEmpty: false,
            phoneNumberEmpty: false,
            emailEmpty: false,
            subjectEmpty: false,
            messageEmpty: false,

            name: null,
            phoneNumber: null,
            email: null,
            subject: null,
            message: null
        }
    },
    methods: {
      closePopup() {
        this.$emit('close');
      },


      sendMessage() {
            var regexPattern = /^\d+$/;
            var regexPatternMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (this.name == null || this.name == "") {
                this.nameEmpty = true
                this.phoneNumberEmpty=false
                this.emailEmpty= false
                this.subjectEmpty= false
                this.messageEmpty= false
            }else if(this.phoneNumber==null || this.phoneNumber==""){
                this.nameEmpty = false;
                this.phoneNumberEmpty=true
                this.emailEmpty= false
                this.subjectEmpty= false
                this.messageEmpty= false
            }else if(this.email==null || this.email==""){
                this.nameEmpty = false;
                this.phoneNumberEmpty=false
                this.emailEmpty= true
                this.subjectEmpty= false
                this.messageEmpty= false
            }else if(this.subject==null || this.subject==""){
                this.nameEmpty = false;
                this.phoneNumberEmpty=false
                this.emailEmpty= false
                this.subjectEmpty= true
                this.messageEmpty= false
            }else if(this.message==null || this.message==""){
                this.nameEmpty = false;
                this.phoneNumberEmpty=false
                this.emailEmpty= false
                this.subjectEmpty= false
                this.messageEmpty= true
            }else if(!regexPattern.test(this.phoneNumber)){
                this.nameEmpty = false;
                this.phoneNumberEmpty=true
                this.emailEmpty= false
                this.subjectEmpty= false
                this.messageEmpty= false
                this.errorMessagge="Please enter a string consisting only of digits."
            }else if(!regexPatternMail.test(this.email)){
                this.nameEmpty = false;
                this.phoneNumberEmpty=false
                this.emailEmpty= true
                this.subjectEmpty= false
                this.messageEmpty= false
                this.errorMessagge="Invalid E-mail format!"
            }
            else{
                this.nameEmpty = false;
                this.phoneNumberEmpty=false
                this.emailEmpty= false
                this.subjectEmpty= false
                this.messageEmpty=false 
                axios.post("https://falcon-website-backend.vercel.app/contact", {
                name: this.name,
                phoneNumber: this.phoneNumber,
                email: this.email,
                subject: this.subject,
                message: this.message
            }).then(function (response) {
                console.log(response)
            }).catch(function (err) {
                console.log(err)
            })

            this.name = "", this.phoneNumber = "", this.email = "", this.email = "", this.subject = "", this.message = "";
            this.$emit('successPopup', this.successPopup);
            this.$emit('close');
            }
        }
    },
  };
  </script>
  
  <style scoped>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }
  
  .popup-content {
    background-color: #CC0605;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 90%;
    width: 400px;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .popup-body {
    text-align: center;
  }
  
  @media (max-width: 480px) {
    .popup-content {
      width: 90%;
      max-width: 300px;
    }
  }


  #title {
    font-size: 40px;
}

.nameClass {
    border: 2px solid rgb(107, 6, 6);
}

input {
    color: black;
}

@media (max-width: 480px) {
    #title {
        font-size: 20px;
    }
}
  </style>