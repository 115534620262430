<template>
    <div class="flex flex-col bgSecondary5">
        <div id="contact" class="h-20"></div>
        <div class="w-full relative">
            <img id="contactImg" class="w-full" src="../assets/contactus.png">
            <div class="absolute flex flex-row md:top-10 md:left-20 top-5 left-5 justify-center items-center">
                <router-link id="homelink" to="/" class="secondary5 tohoma md:text-base text-xs font-normal md:mr-5 mr-2">Home</router-link>
                <img class="w-2 h-3 md:mr-5 mr-2" src="../assets/vectorcareer.png">
                <p class="secondary5 tohoma font-bold md:text-base text-xs">Contact Us</p>
            </div>
            <div class="absolute flex flex-col md:bottom-24 md:left-20 bottom-2 left-5">
                <p id="title" class="secondary5 font-bold md:mb-8 mb-2">Get In Touch</p>
                <p class="secondary5 helvatica font-normal md:text-xl text-xs w-2/3">For your any kind of maintenance or operational needs please do not hesitate to contact us through following channels.</p>
            </div>
        </div>
        <!-- <div class="flex-col w-full md:mt-28 mt-20 pl-5 pr-5 md:pr-20 md:pl-20 justify-center items-center">
            <img class="w-full rounded-lg md:mb-8 mb-4" src="../assets/EBACE_final_230406-2.jpg">
            <p id="ebacetitle" class="tohoma font-bold primary flex justify-center items-center">Please visit us at EBACE
                2023, Booth No : X41</p>
        </div> -->
        <div class="md:mt-28 mt-20 md:ml-20 md:mr-20 ml-5 mr-5 flex md:flex-row flex-col md:mb-28 mb-20">
            <iframe class="w-full md:w-3/5 md:mb-0 mb-5"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d767952.2205216705!2d28.74455655824482!3d41.247623241285574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa388c0d3c6eb%3A0xa0274409c00a6064!2sFalcon%20Aerospace%20Arge%20A.%C5%9E!5e0!3m2!1str!2str!4v1679922686495!5m2!1str!2str"
                allowfullscreen="" loading="lazy" zoom="15" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <div class="flex flex-col md:pl-20 w-full md:w-2/5">
                <p id="contacttitle" class="primary font-bold tohoma mb-8">HQ & Hangar Address</p>
                <div class="flexflex-col w-full">
                    <div class="flex flex-row mb-8">
                        <img class="w-4 h-5 mr-5 mt-1" src="../assets/mapsIcon.png">
                        <p class="w-full helvatica font-normal secondary md:text-base text-sm">Istanbul Atatürk Havalimanı – Falcon
                            Hangar
                            Yeşilköy Mah. İnönü Cd. No3-1/11
                            Bakırköy / Istanbul / Türkiye</p>
                    </div>
                    <div class="flex flex-row mb-8">
                        <img class="w-4 h-5 mr-5 mt-1" src="../assets/phone.png">
                        <p class="w-full helvatica font-normal secondary md:text-base text-sm">P/F : +90 212 540 4062</p>
                    </div>
                    <div class="flex flex-row mb-8">
                        <img class="  w-5  h-4 mr-5 mt-1" src="../assets/message.png">
                        <p class="w-full helvatica font-normal secondary md:text-base text-sm">info@falconaerospace.ist</p>
                    </div>
                    <div class="flex flex-col mb-8">
                        <p class="tohoma font-bold md:text-base text-sm primary">7/24 AOG Support </p>
                        <p class="tohoma font-normal md:text-base text-sm secondary">aog@falconaerospace.ist / +90 532 570 7000</p>
                    </div>

                    <div class="flex flex-col">
                        <p class="tohoma font-bold md:text-base text-sm primary">For Scheduled and Unscheduled Maintenance</p>
                        <p class="tohoma font-bold md:text-base text-sm secondary mb-6">Mr. Ramazan ÇOKAKIL / Technical Director</p>
                        <div class="flex flex-row mb-8">
                            <img class="w-4 h-5 mr-5 mt-1" src="../assets/phone.png">
                            <p class="w-full helvatica font-normal secondary md:text-base text-sm">+90 532 570 7000</p>
                        </div>
                        <div class="flex flex-row mb-8">
                            <img class="  w-5  h-4 mr-5 mt-1" src="../assets/message.png">
                            <p class="w-full helvatica font-normal secondary md:text-base text-sm">ramazan.cokakil@falconaerospace.ist</p>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <p class="tohoma font-bold md:text-base text-sm primary">For Parts </p>
                        <p class="tohoma font-bold md:text-base text-sm secondary mb-6">Mrs. Demet AYDIN/Supply Chain & Logistics Manager</p>
                        <div class="flex flex-row mb-8">
                            <img class="w-4 h-5 mr-5 mt-1" src="../assets/phone.png">
                            <p class="w-full helvatica font-normal secondary md:text-base text-sm">+90 546 542 5061</p>
                        </div>
                        <div class="flex flex-row mb-8">
                            <img class="  w-5  h-4 mr-5 mt-1" src="../assets/message.png">
                            <p class="w-full helvatica font-normal secondary md:text-base text-sm">demet.aydin@falconaerospace.ist</p>
                        </div>
                    </div>
                    <!-- <div class="flex flex-col">
                        <p class="tohoma font-bold md:text-base text-sm primary">For Unscheduled Maintenance</p>
                        <p class="tohoma font-bold md:text-base text-sm secondary mb-6">Mr. Ramazan Çokakıl / Line Maint. Manager</p>
                        <div class="flex flex-row mb-8">
                            <img class="w-4 h-5 mr-5 mt-1" src="../assets/phone.png">
                            <p class="w-full helvatica font-normal secondary md:text-base text-sm">+90 532 570 7000</p>
                        </div>
                        <div class="flex flex-row mb-8">
                            <img class="  w-5  h-4 mr-5 mt-1" src="../assets/message.png">
                            <p class="w-full helvatica font-normal secondary md:text-base text-sm">ramazan.cokakil@falconaerospace.ist</p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <ContactusComponent class="md:mb-28 mb-20" widthFull="true"  @success-popup="onSuccessPopup"/>
        <SuccesspopupComponents v-if="succesPopup" class="absolute w-full h-full top-0 z-20" />
    </div>
</template>
<script>
import SuccesspopupComponents from "@/components/SuccesspopupComponents.vue";
import ContactusComponent from "@/components/ContactusComponent.vue"
export default {
    components:{
    ContactusComponent,
    SuccesspopupComponents
    },
    data() {
        return {
            succesPopup:false
        }
    },
methods: {
    onSuccessPopup() {
            this.succesPopup = true;
            window.scrollTo(0, 0);
            setTimeout(() => {
                this.succesPopup = false;
            }, 3000);
        },
},
mounted() {
    window.scrollTo(0, 0);
},
}
</script>
<style scoped>
#ebacetitle {
    font-size: 32px;
}
#title{
    font-size: 40px;
}
iframe {
    height: 900px;
    border-radius: 8px;
}

#contacttitle {
    font-size: 32px;
}
#contactImg{
    filter: brightness(80%) grayscale(30%);
}
@media (max-width: 480px) {
    iframe {
        height: 400px;
    }
    #title{
        font-size: 20px;
    }
    #ebacetitle {
    font-size: 16px;
}
#contacttitle{
    font-size: 16px;
}
}
</style>