<template>
    <div class="flex justify-center  flex-col xl:flex-row p-10 pr-5 secondary4Bg">
        <div class="logo-about mr-16">
            <div class="logo">
                <router-link to="/#home">
                    <img  src="../assets/FalconAerospacelogo.png">
                </router-link>
                <p>Falcon Aerospace is an integrated aviation company with maintenance/repair/overhaul , air taxi operations and ground services capability under one umbrella.</p>
            </div>
            <div class="flex">
                <div class="flex align-center flex-row">
                    <a href="https://www.linkedin.com/company/falcon-aerospace-arge-a-s/" target="_blank" class="m-2 cursor-pointer"><img class="w-5" src="../assets/linkedin.png"></a>
                    <a class="m-2 cursor-pointer"><img class="w-5" src="../assets/twitter.png"></a>
                    <a class="m-2 cursor-pointer"><img class="w-5" src="../assets/facebbok.png"></a>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap">
            <div class="flex py-6 flex-col md:w-1/3 w-1/4">
                <p class="text-sm font-bold primary mb-2">About Us</p>
                <router-link to="/about#about">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Overview</p>
                </router-link>
                <router-link to="/about#history">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">History</p>
                </router-link>
                <router-link to="/about#mission">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Mission & Vission</p>
                </router-link>
                <router-link to="/about#team">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Team</p>
                </router-link>
                <router-link to="/about#approvals">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Approvals</p>
                </router-link>
                <router-link to="/about#memberships">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Membership</p>
                </router-link>
            </div>
            <div class="py-6 w-1/3">
                <p class="text-sm font-bold primary mb-2">Maintenance Services</p>
                <router-link to="/maintenanceservices#aircraft">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Aircraft</p>
                </router-link>
                <router-link to="/maintenanceservices#engine">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Engine & Apu Support</p>
                </router-link>
                <!-- <router-link to="/maintenanceservices#components">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Components</p>
                </router-link> -->
                <router-link to="/maintenanceservices#avionics">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Avionics</p>
                </router-link>
                <router-link to="/maintenanceservices#purchase">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Pre-Purchase Inpections</p>
                </router-link>
                <router-link to="/maintenanceservices#cabin">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Interior & Exterior Cleaning</p>
                </router-link>
            </div>
            <div class="py-6 w-1/3">
                <p class="text-sm font-bold primary mb-2">Aircrafts</p>
                <router-link to="/bombardierchallanger604#bombardierchallanger">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Bombardier Challenger 604</p>
                </router-link>
                <router-link to="/bombardierchallanger850#challanger">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Bombardier Challenger 850</p>
                </router-link>
                <router-link to="/bombardierglobalSeries#challanger">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Bombardier Global Series</p>
                </router-link>
                <router-link to="/hawker#hawker">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Hawker</p>
                </router-link>
                <router-link to="/embarerLegacy#embarerlegacy">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Embraer 135/145 Legacy 600/650</p>
                </router-link>
            </div>
            <div class="py-6 md:w-1/3 w-1/4">
                <p class="text-sm font-bold primary mb-2">AOG Support</p>
                <router-link to="/aogsupport#maintenance">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Maintenance</p>
                </router-link>
                <router-link to="/aogsupport#parts">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Parts</p>
                </router-link>
            </div>
            <div class="py-6 w-1/3 md:mr-0 pr-2">
                <p class="text-sm font-bold primary mb-2">Careers</p>
                <router-link to="/careers#job">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Job Openings</p>
                </router-link>
                <router-link to="/careers#general">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">General Application Form</p>
                </router-link>
            </div>
            <div class="py-6 w-1/3 md:mr-0 pr-2">
                <p class="text-sm font-bold primary mb-2">Contact Us</p>
                <p class="mb-1 text-xs  cursor-pointer font-normal secondary">Yeşilköy Mah. İnönü Cad. No:3-1/11 Atatürk
                    Havalimanı Genel Havacılık Apronu - Falcon Hangar BAKIRKÖY/İSTANBUL </p>
                <router-link to="#">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">info@falconaerospace.ist</p>
                </router-link>
                <router-link to="#">
                    <p class="mb-1 text-xs  cursor-pointer font-normal secondary">(+90) 212 540 40 62</p>
                </router-link>
            </div>
        </div>
    </div>
    <div class="w-full red-bg h-14 p-0 flex items-center">
        <p class="pl-10 text-white text-sm font-normal flex items-center">© 2023 FALCON AEROSPACE.Tüm hakları saklıdır.</p>
    </div>
</template>
<script>
export default {

}
</script>
