<template>
    <div class="flex flex-col w-full md:pr-20 md:pl-20 pr-5 pl-5 md:mb-28 mb-20 ">
        <p id="title" class="tohoma font-bold mb-8">Team</p>
        <!-- <p class="helvatica font-normal secondary md:text-base text-sm md:mb-24 mb-16">Having a well qualified
            management team with more than
            100 years cumulative experience in civil aviation,
            and dedicated operational team we are able to fulfill all customer needs.</p> -->
        <div class="flex flex-col justify-center items-center">
            <div v-for="(item, index) in team.slice(0, 1)" :key="index"
                class="flex  flex-col justify-center items-center relative md:w-1/3 lg:w-1/3 w-full md:pr-10 md:pl-10">
                <img class="rounded-lg w-full" :src="item.img">
                <p class=" text-center h-10 tohoma font-bold text-xl mt-4 mb-4">{{ item.name }}</p>
                <p class="h-16 helvatica font-bold primary flex text-base text-center">{{ item.title }}</p>
            </div>
            <div class="flex md:flex-row flex-col w-full items-center justify-center">
                <div v-for="(item, index) in team.slice(1, 5)" :key="index"
                    class="flex md:pr-10 md:pl-10 flex-col justify-center items-center relative md:w-1/3 w-full lg:w-1/3">
                    <img class="rounded-lg w-full " :src="item.img">
                    <p class=" text-center h-10 tohoma font-bold text-xl mt-4 mb-4">{{ item.name }}</p>
                    <p class="h-16 helvatica font-bold primary flex text-base text-center">{{ item.title }}</p>
                </div>
                <!-- <div class="w-full md:hidden flex flex-col justify-center items-center p-6  relative">
                <img class="rounded-lg" :src="teamMobile.img">
                <p class="tohoma font-bold md:text-xl text-sm pt-4 mb-1">{{ teamMobile.name }}</p>
                <p class="helvatica font-bold primary flex  md:text-base text-xs text-center">{{ teamMobile.title }}</p>
            </div> -->
            </div>
        </div>
        <!-- <div class="md:flex hidden flex-row justify-center items-center mt-8">
            <button @click="onchangeButton(0)" :class="{ 'active': selectedButton === 0 }"
                class="secondary4 w-2.5 h-2.5 rounded-full mr-2.5"></button>
            <button @click="onchangeButton(1)" :class="{ 'active': selectedButton === 1 }"
                class="secondary4 w-2.5 h-2.5 rounded-full"></button>
        </div>
        <div class="md:hidden flex flex-row justify-center items-center mt-8">
            <button v-for="(item, index) in team" :key="index" @click="onchangeButtonMobile(item,index)"
                :class="{ 'active': selectedButton === index }" class="secondary4 w-2.5 h-2.5 rounded-full mr-2.5"></button>
        </div> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            teamMobile: {
                img: require(`../assets/team1.jpg`),
                name: "Mr. Murat Burak Atabey",
                title: "Chairman"
            },
            selectedButton: 0,
            team: [
                {
                    img: require(`../assets/team1.jpg`),
                    name: "Mr. Murat Burak ATABEY",
                    title: "Chairman"
                },
                {
                    img: require(`../assets/ramazancokakgil.jpg`),
                    name: "Mr. Ramazan ÇOKAKIL",
                    title: "Technical Director"
                },
                {
                    img: require(`../assets/demetAydin.jpg`),
                    name: "Mrs. Demet AYDIN",
                    title: "Logistics Manager"
                },
                {
                    img: require(`../assets/ersinbagit.jpg`),
                    name: "Mr. Ersin BAĞİT",
                    title: "Engineering & Planning Manager"
                },
                // {
                //     img: require(`../assets/team3.jpg`),
                //     name: "Mr. Vahap ÖNEN",
                //     title: "Safety & Compliance Monitoring Director"
                // },
                // {
                //     img: require(`../assets/team2.jpg`),
                //     name: "Mr. Atilla Güney",
                //     title: "General Manager"
                // },
                // {
                //     img: require(`../assets/team4.jpg`),
                //     name: "Mr. Halil Hasanusta",
                //     title: "Technical Director"
                // },
                // {
                //     img: require(`../assets/team5.jpg`),
                //     name: "Mr. Rıdvan Bay",
                //     title: "Logistics Director"
                // }
            ]
        }
    },
    methods: {
        onchangeButton(item) {
            this.selectedButton = item
            if (item == 0) {
                var user1 = this.team.pop()
                this.team.unshift(user1)
            } else if (item == 1) {
                var user = this.team.shift();
                this.team.push(user)
            }
        },
        onchangeButtonMobile(item, index) {
            this.selectedButton = index
            this.teamMobile = item
        }
    }
}
</script>
<style scoped>
.active {
    width: 32px;
    height: 10px;
    border-radius: 10px;
    background-color: #CC0605;
}

#title {
    font-size: 32px;
}

@media(max-width: 480px) {
    #title {
        font-size: 16px;
    }
}
</style>